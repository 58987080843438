.card-wrapper {

    a {
        color: initial;
    }

    :first-child {
       margin-left: 0 !important;     
    }

    .card-container {
        width: 100%;
        padding: 25px 27px 24px 29px;
        justify-content: center;
        border-radius: 20px;
        background-color: #FFD9D6;
        height: 100%;

        &:hover {
            cursor: pointer;
        }
        
        @media screen and (min-width: 991px) {
            width: 323px; 
            margin: 0 15px;
        }

        .card-content{

            * {
                margin: 0;
            }
        
            h5 {
                font-size: 16px;
            }
        
            p {
                margin-top: 5px !important;
                font-size: 12px;
                line-height: 14px;
            }
        
        }

        &.expired {
            background-color: #9F9F9F;

            &:hover {
                cursor: default;
            }

            .icon-container {
                background: #6C6C6C !important;
                color: #ffffff;
                svg path {
                    fill: #ffffff;
                }
            }
            
            .card-content {
                * {
                    color: #fff;
                }
            }
        }
    }

    @media screen and (max-width: 990px) {
        flex-direction: column;
    }

}



.services-card-wrapper {

    :first-child {
       margin-left: 0 !important;     
    }

    :last-child {
        margin-right: 0 !important;     
     }

    .services-card-container {
        width: 100%;
        height: 100%;
        border-radius: 35px;
        background-color: #FFD9D6;
        margin: 10px;
        position: relative;
        overflow: hidden;

        .card-content {
            height: 100%;
            background: linear-gradient(
                to top,
                #FFD9D6 0%,
                #FFD9D6 50%,
                #ffffff 50%,
                #ffffff 100%
            );
            padding: 50px 21px 21px;
            align-items: center;
            position: relative;
            display: flex;
            flex-flow: column;
            border-radius: 38px;
            border: 4px solid #FFD9D6;
        }

        &:hover {
            div.overlay {
                opacity: 1;
            }
        }

        @media screen and (min-width: 991px) {
            width: 337px;
            height: 302px;
        }

        @media screen and (max-width: 992px) {
            margin-left: 0;
            margin-right: 0;
        }

        h5 {
            margin-bottom: 8px;
        }

        .services-icon {
            width: 104px;
            height: 104px;
        }

        p {
            text-align: center;
            margin-top: 15px;
            font-size: 14px;
            font-weight: 700;
            line-height: 17px;
            white-space: pre-wrap;
        }

        .subscribed {
            position: absolute;
            top: 0;
            background-color: #D4574D;
            color: #FFD9D6;
            padding: 9px 16px 12px;
            font-weight: 700;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            font-size: 16px;
            line-height: 19px;

            span{
                padding-right: 4px;

                svg {
                    width: 16px;
                    height: 16px;

                    path {
                        fill: #FFD9D6;
                    }
                }
            }

            
        }

        div.overlay {
            display: flex;
            justify-content: center;
            align-items: center;
    
            width: 100%;
            height: 100%;
            border-radius: 20px;
    
            position: absolute;
    
            background-color: rgba(0, 0, 0, 0.3);
    
            transition: 0.3s;
    
            opacity: 0;
            z-index: 999;
    
            button {
                border-radius: 26px;
            }
        }
    }

    @media screen and (max-width: 990px) {
        flex-direction: column;
    }

}

$buttonXPadding: 32px;
$buttonYPadding: 8px;

// popup-styles
// div.swal2-popup.swal-manage-subscription-page {
//     width: 100%;
//     max-width: 563px;
//     padding: 27px 32px 24px;
//     border-radius: 30px;
//     @media screen and (min-width: 481px) {
//         padding: 16px 32px 48px;
//     }

//     .swal2-html-container {
//         a {
//             color: #D4574D;
//             &:hover {
//                 text-decoration: underline;
//             }
//         }
//     }

//     &.cancel {
//         @media screen and (min-width: 481px) {
//             padding: 50px 75px 45px;
//         }

//         div.swal2-html-container {
//             margin: 0;
//             div.end-date {
//                 padding: 32px 0;
//                 font-size: 14px;
//                 line-height: 1;
//                 color: #000;
//                 @media screen and (min-width: 481px) {
//                     font-size: 22px;
//                 }
//                 b { display: block; margin-top: 5px; }
//             }
//             div.question {
//                 margin-bottom: 32px;
//                 font-size: 14px;
//                 font-weight: 700;
//                 line-height: 1;
//                 color: #000;
//                 @media screen and (min-width: 481px) {
//                     font-size: 22px;
//                 }
//             }
//             p {
//                 font-size: 11px;
//                 font-weight: 400;
//                 color: #000;
//                 @media screen and (min-width: 481px) {
//                     font-size: 14px;

//                     &.sub-content-text {
//                         margin: 0 20px;
//                     }
//                 }
//             }
            
//         }

//         .swal2-actions {
//             @media screen and (min-width: 481px) {
//                 margin-top: 35px;
//                 padding: 0 32px;
//             }
//         }
//     }

//     &.loyalty {

//         @media screen and (min-width: 481px) {
//             padding: 50px 75px 45px;
//         }

//         div.title {
//             padding: 32px 0;
//         }
//         div.title, p {
//             font-size: 14px;
//             font-weight: 400;
//             line-height: 1;
//             color: #000;

//             @media screen and (min-width: 481px) {
//                 font-size: 24px;
//             }
//         }

//         .swal2-html-container {
//             margin: 0;
//         }

//         .swal2-actions {
//             @media screen and (min-width: 481px) {
//                 margin-top: 35px;
//                 padding: 0 50px;
//             }
//         }
//     }

//     &.termination, &.isp {
//         @media screen and (min-width: 481px) {
//             padding: 50px 75px 45px;
//         }
    
//         div.swal2-html-container {
//             margin: 0;
//             div.question {
//                 padding-top: 38px;
//                 font-weight: 700;
//                 font-size: 14px;
//                 line-height: 1;
//                 color: #000;

//                 @media screen and (min-width: 481px) {
//                     padding: 22px 0;
//                     font-size: 22px;
//                 }
//             }
//             select {
//                 width: 100%;
//                 max-width: 400px;
//                 padding: 12px 12px;
//                 border: 2px solid #aaa;
//                 border-radius: 8px;
//                 margin-left: auto;
//                 margin-right: auto;
//                 margin-bottom: 0;
//                 font-size: 12px;
//                 font-weight: 400;

//                 @media screen and (min-width: 481px) {
//                     margin-bottom: 24px;
//                     font-size: 20px;
//                 }
//             }
//         }

//         .swal2-actions {
//             @media screen and (min-width: 481px) {
//                 margin-top: 11px;
//                 padding: 0 50px;
//             }
//         }
//     }

//     &.sorry {
//         max-width: 487px;
//         div.swal2-html-container {
//             div.title {
//                 padding: 18px 0;
//                 font-size: 16px;
//                 font-weight: 700;
//                 line-height: 1;
//                 color: #000;

//                 @media screen and (min-width: 481px) {
//                     font-size: 22px;
//                 }
//             }
//             div.swal-icon {
//                 margin-bottom: 28px;
//             }
//             p {
//                 font-size: 12px;
//                 font-weight: 400;
//                 color: #000;

//                 @media screen and (min-width: 481px) {
//                     font-size: 20px;
//                 }
//             }
//         }
//     }

//     &.subscriptionnotif-success {
//         max-width: 487px;
//         .swal2-title {
//             padding-top: 33px;
//             font-size: 20px;
//             font-weight: 700;
//             @media screen and (min-width: 481px) {
//                 padding-top: 16px;
//                 font-size: 24px;
//             }
//         }

//         .swal2-html-container {
//             font-size: 14px;
//             margin: 0;
//             margin-top: 13px;

//             @media screen and (min-width: 481px) {
//                 margin: 27px 0 0;
//                 font-size: 20px;
//             }
//         }

//         .swal2-actions {
//             padding: 0;
//             margin-top: 33px;

//             @media screen and (min-width: 481px) {
//                 padding: 0 44px;
//             }
//             button {
//                 width: 100%;
//                 font-size: 16px;

//                 @media screen and (min-width: 481px) {
//                     font-size: 20px;
//                 }
//             }
//         }
//     }

//     &.error-cancel {
//         max-width: 487px;
//         padding: '10px 20px 48px';

//         .swal2-html-container {
//             font-weight: 700;
//         }

//         .swal2-icon {
//             border:none;
//             .swal2-icon-content{
//                 svg {
//                     width: 93px;
//                     height: 93px;
//                     path {
//                         fill: #d4574d;
//                     }
//                 }
//             }
//         }

//         @media screen and (max-width: 480px) {

//             .swal2-html-container {
//                 font-size: 14px;
//                 margin-top: 0;
//             }

//             .swal2-icon {
//                 margin-top: 0;
//                 .swal2-icon-content{
//                     svg{
//                         width: 63px;
//                         height: 63px;
//                     }
//                 }
//             }
            
//         }
//     }

//     &.vaspopupcustom {
//         max-width: 100%;
//     }

//     &.vaspopup {
//         background: linear-gradient(
//             to bottom,
//             #FFD9D6 0,
//             #FFD9D6 106px,
//             #ffffff 106px,
//             #ffffff 100%
//         );
//         @media screen and (min-width: 481px) {
//             background: linear-gradient(
//             to bottom,
//             #FFD9D6 0,
//             #FFD9D6 134px,
//             #ffffff 134px,
//             #ffffff 100%
//         );
//         }
//         .vaspopup-content {
//             margin-left: 0;
//             margin-right: 0;
//             margin-top: 0;
//             @media screen and (min-width: 481px) {
//                 margin-top: 18px;
//             }
//             .vaspopup-title {
//                 font-size: 18px;
//                 margin: 10px auto 10px;

//                 @media screen and (min-width: 481px) {
//                     margin: 16px auto 10px;
//                     font-size: 24px;
//                 }
//             }

//             .vaspopup-inner {
//                 margin-top: 10px;
//                 overflow-x: hidden;
//                 @media screen and (min-width: 481px) {
//                     margin-top: 22px;
//                 }
                
//                 .btn {
//                     border-radius: 26px;
//                     border: 2px solid #D4574D;
//                     font-size: 16px;
//                     padding: 6px 18px;
//                     font-weight: 700;
//                     @media screen and (min-width: 481px) {
//                         padding: 13px 32px;
//                         font-size: 20px;
//                     }
//                     &:hover {
//                         background-color: #D4574D;
//                     }
//                 }

//                 &.two-col {
//                     display: flex;

//                     .col-inner {
//                         width: 50%;
//                         padding-left: 0;
//                         @media screen and (min-width: 481px) {
//                             padding-left: 43px;
//                         }
//                         &.right {
//                             .MuiFormControl-root {
//                                 margin-bottom: 14px;
//                                 @media screen and (min-width: 481px) {
//                                     margin-bottom: 20px;
//                                 }
//                             }
//                         }
//                         .MuiFormControl-root {
//                             font-size: 14px;
//                             @media screen and (min-width: 481px) {
//                                 font-size: 20px;
//                             }
//                             .MuiFormLabel-root {
//                                 font-size: 14px;
//                                 font-weight: 700;
                                
//                                 @media screen and (min-width: 481px) {
//                                     font-size: 20px;
//                                 }
//                             }

//                             .MuiSwitch-track {
//                                 width: 34px;
//                                 height: 18px;
//                                 @media screen and (min-width: 481px) {
//                                     width: 64px;
//                                     height: 32px;
//                                 }

//                                 .MuiSwitch-thumb {
//                                     width: 16px;
//                                     height: 16px;
//                                     left: 10px;
//                                     @media screen and (min-width: 481px) {
//                                         width: 24px;
//                                         height: 24px;
//                                         left: 16px;
//                                     }
//                                 }
//                             }
//                             .custom-switch {
//                                 input {
//                                     &:checked {
//                                         +.custom-control-label {
//                                             &::after {
//                                                 transform: translateX(14px);

//                                                 @media screen and (min-width: 481px) {
//                                                     transform: translateX(30px);
//                                                 }
//                                             }

//                                             &::before {
//                                                 background: #149635;
//                                             }
//                                         }
//                                     }
//                                 }
                                
//                             }
//                             .custom-control-label {
//                                 &::before {
//                                     width: 34px;
//                                     height: 18px;

//                                     @media screen and (min-width: 481px) {
//                                         width: 64px;
//                                         height: 32px;
//                                     }
//                                 }
//                                 &::after {
//                                     width: 16px;
//                                     height: 16px;

//                                     @media screen and (min-width: 481px) {
//                                         width: 30px;
//                                         height: 30px;
//                                     }
//                                 }
//                             }
//                         }
//                     }
//                 }

//                 &.vas-securenet {
//                     font-size: 20px;
//                     padding: 20px 0 30px;
//                     .form-radiogroup {
//                         margin: 0;
//                         .radiowith-tooltip-wrap {
//                             display: flex;
//                             margin: 6px 0;
//                             align-items: center;
                            
//                             @media screen and (min-width: 481px) {
//                                 margin: 12px 0;
//                             }
//                             svg {
//                                 width: 16px;
//                                 height: 16px;
//                             }

//                             .MuiRadio-root {
//                                 margin-right: 13px;

//                                 .MuiRadio-radio{
//                                     border-width: 3px;
//                                 }
//                             }
//                         }

//                         .MuiRadio-root {
//                             text-align: left;
//                             label {
//                                 display: flex;
//                                 align-items: center;
//                                 font-weight: 700;
//                                 margin-bottom: 0;
//                                 font-size: 14px;

//                                 @media screen and (min-width: 481px) {
//                                     font-size: 18px;
//                                 }
//                             }
//                         }
//                     }

//                     .form-switch, .custom-switch {
//                         text-align: left;

//                         .custom-control-input:checked ~ .custom-control-label::after{
//                             transform: translateX(2.3rem);
//                             background-color: #fff;
//                         }

//                         .custom-control-label{
                        
//                             &::before{
//                                 width: 69px;
//                                 height: 31px;
//                                 border-radius: 32px;
//                                 background: #d4574d;
//                             }

//                             &::after {
//                                 top: 6px;
//                                 left: -34px;
//                                 width: 28px;
//                                 height: 28px;
//                                 background-color: #fff;
//                                 animation: none;

//                                 @media screen and (min-width: 481px) {
//                                     top: 7px;
//                                 }
//                             }

                            

                            
//                         }
//                     }

//                     .form-select {
//                         width: 100%;
//                         border: 3px solid #A9A9A9;
//                         border-radius: 12px;
//                         padding: 3px 5px 3px 8px;
//                         font-size: 14px;
//                         -webkit-appearance: none;
//                         color: #646464;
//                         // appearance: none;
//                         background: url('../../../images/select-arrow-down.svg') no-repeat 97% 50%;

//                         &:focus, &:active, &:hover {
//                             background: url('../../../images/select-arrow-down.svg') no-repeat 97% 50%;
//                         }

//                         @media screen and (min-width: 481px) {
//                             width: 212px;
//                             font-size: 18px;
//                         }
//                     }
//                 }
//             }

//             .col-buttons {
//                 display: flex;
//                 justify-content: space-around;
//                 @media screen and (max-width: 480px) {
//                     justify-content: space-between;
//                 }

//                 .btn {
//                     max-width: 100%;
//                     min-width: 124px;
//                     border-radius: 30px;
//                     font-size: 16px;
//                     padding: 8px 16px;
//                     font-weight: 700;

//                     @media screen and (min-width: 481px) {
//                         min-width: 234px;
//                         padding: 12px;
//                         font-size: 24px;
//                     }

//                     &.cancel-btn {
//                         background-color: #676767 !important;
//                         border-color: #676767 !important;
//                         &:hover {
//                             background-color: #676767 !important;
//                         }
//                     }

//                     &.update-btn {
//                         background-color: #D4574D !important;
//                         border-color: #D4574D !important;
//                         &:hover {
//                             background-color: #D4574D !important;
//                         }
//                     }

                   
//                 }
//             }
//         }

//         .vaspopup-closebtn {
//             background-color: #D4574D;
//             padding-bottom: 3px;
//             color: #fff;
//             width: 31px;
//             height: 31px;
//             font-size: 2em;
//         }

//         .swal2-actions {
//             margin-top: 23px;
//             @media screen and (min-width: 481px) {
//                 margin-top: 39px;
//             }
//             .unsubscribe-btn {
//                 // padding-left: 56px;
//                 // padding-right: 56px;
//                 width: 100%;
//                 max-width: 152px;
//                 padding: 8px;
//                 @media screen and (min-width: 481px) {
//                     max-width: 222px;
//                     padding: 12px;
//                 }
//             }
//         }
//     }

//     &.vasUnsubscribe {
//         max-width: 487px;

//         .vasUnsubscribe-content {

//             .vasUnsubscribe-text {
//                 margin-bottom: 20px;

//                 p {
//                     font-size: 16px;
//                     margin: 0;
//                     @media screen and (min-width: 481px) {
//                         font-size: 22px;
//                     }
//                 }
//             }
//         }

//         .vasUnsubscribe-action-btn {
//             padding:0 33px;

//             button {
//                 min-width: 120px;
//                 outline: none;
//             }
//         }
//     }

//     &.vasUnsubscribeConfirm {
//         max-width: 487px;
//         padding: 32px;
//         @media screen and (min-width: 481px) {
//             padding: 50px 20px 48px;
//         }
//         .vasUnsubscribeConfirm-content {
//             margin-left: 0;
//             margin-right: 0;
//             margin-top: 0;
//             @media screen and (min-width: 481px) {
//                 margin-top: 18px;
//             }
//             p {
//                 font-size: 16px;
//                 @media screen and (min-width: 481px) {
//                     font-size: 20px;
//                 }
//                 strong, b {
//                     font-weight: 700;
//                 }
//             }

//             .agreeTerms {
//                 display: flex;
//                 justify-content: center;
//                 align-items: center;
//                 margin-top: 37px;
//                 margin-bottom: 11px;
//                 font-size: 14px;
//                 @media screen and (min-width: 481px) {
//                     font-size: 18px;
//                 }
//                 .agree-checkbox {
//                     margin-right: 6px;
//                     @media screen and (min-width: 481px) {
//                         margin-right: 12px;
//                     }
//                     .MuiCheckbox-checkbox {
//                         width: 15px;
//                         height: 15px;
//                         border-width: 2px;

//                         &.Joy-checked {
//                             svg {
//                                 width: 0.7em;
//                                 height: 0.7em;
//                             }
//                         }

//                         @media screen and (min-width: 481px) {
//                             width: 20px;
//                             height: 20px;
//                             border-width: 3px;

//                             &.Joy-checked {
//                                 svg {
//                                     width: 1em;
//                                     height: 1em;
//                                 }
//                             }
//                         }
//                     }
//                 }

//                 a {
//                     color: #D4574D;

//                     &:hover {
//                         text-decoration: underline;
//                     }
//                 }
//             }
//         }

//         .vasUnsubscribeConfirm-action-btn {
//             padding:0;
//             @media screen and (min-width: 481px) {
//                 padding:0 32px;
//             }
//             button {
//                 width: 100%;
//                 max-width: 104px;
//                 padding: 8px 0;
//                 outline: none;
//                 @media screen and (min-width: 481px) {
//                     max-width: 122px;
//                     padding: 12px 0;
//                     font-size: 20px;
//                 }
//             }
//         }
//     }

//     &.subscribepopup {
//         max-width: 487px;
//         padding: 32px;
//         @media screen and (min-width: 481px) {
//             padding: 50px 20px 48px;
//         }
//         .subscribe-content {
//             p {
//                 font-size: 14px; 
//                 @media screen and (min-width: 481px) {
//                     font-size: 20px; 
//                 }

//                 &.monthlyCharges {
//                     margin-top: 0;

//                     @media screen and (min-width: 481px) {
//                         margin-top: 34px;
//                     }
//                 }
//             }

//             strong {
//                 color: #000;
//             }
//         }

//         .subscribe-action-btn {
//             padding:0 33px;

//             button {
//                 min-width: 104px;
//                 outline: none;

//                 @media screen and (min-width: 481px) {
//                     min-width: 122px;
//                 }
//             }
//         }
//     }

//     &.unsubscribepopup, &.subscribepopup-success {
//         max-width: 487px;
//         padding: '10px 20px 48px';

//         .swal2-html-container {
//             font-weight: 700;
//         }

//         .swal2-icon {
//             border:none;
//             .swal2-icon-content{
//                 svg {
//                     width: 93px;
//                     height: 93px;
//                 }
//             }
//         }

//         @media screen and (max-width: 480px) {

//             .swal2-title{
//                 font-size: 16px;
//                 padding-top: 0;
//             }

//             .swal2-html-container {
//                 font-size: 14px;
//             }

//             .swal2-icon {
//                 margin-top: 0;
//                 .swal2-icon-content{
//                     svg{
//                         width: 63px;
//                         height: 63px;
//                     }
//                 }
//             }
            
//         }


//     }

    
//     div.swal2-actions {
//         width: 100%;
//         padding: 0;
//         @media screen and (min-width: 481px) {
//             padding: 0 80px;
//         }

//         button {
//             margin: 0 auto;
//             border-radius: 30px;
//             box-shadow: none;
//             font-size: 16px;
//             font-weight: 700;
//             padding-top: $buttonYPadding;
//             padding-bottom: $buttonYPadding;
//             min-width: 104px;
//             outline: none;

//             @media screen and (min-width: 481px) {
//                 min-width: 122px;
//                 font-size: 24px;
//             }
            
//             &:hover, &:active, &:focus {
//                 box-shadow: none;
//             }
//         }
//     }
// }

// .MuiTooltip-root {
//     background-color: #646464 !important;
// }

// .MuiTooltip-arrow {
   
//     &::before{
//     border-top-color: #646464 !important;
//     border-right-color: #646464 !important;
//    }
// }