$buttonXPadding: 32px;
$buttonYPadding: 8px;

div.swal2-popup{
    max-width: calc((80 / 100) * 100vw);
    width: 100%;
    border-radius: 30px;
    padding: 30px 0 32px;

    @media screen and (min-width: 481px) {
        max-width: 487px;
        padding: 40px 20px 48px;
    }

    // @media screen and (max-width: 415px) {
    //     // max-width: 333px
    //     max-width: calc((80 / 100) * 100vw);
    // }

    /* Action Button */
    .swal2-actions {
        width: 100%;
        padding: 0 15%;
        
        @media screen and (min-width: 481px) {
            padding: 0 60px;
            flex-flow: row;
        }

        @media screen and (max-width: 415px) {
            padding: 0 8%;
        }

        button {
            margin: 0 auto 10px;
            border-radius: 100rem;
            font-size: 16px;
            font-weight: 700;
            padding-top: $buttonYPadding;
            padding-bottom: $buttonYPadding;
            min-width: 104px;
            outline: none;

            @media screen and (min-width: 481px) {
                font-size: 24px;
                min-width: 122px;
            }

            @media screen and (max-width: 415px) {
            }

            // &.swal2-confirm {
            //     padding-left: $buttonXPadding;
            //     padding-right: $buttonXPadding;
            // }

            &:hover, &:active, &:focus {
                box-shadow: none;
            }

            &.swal2-confirm {
                background-color: #d4574d;
            }
        }

        &.vaspopup-promo-btns {
            
            button{
                @media screen and (min-width: 481px) {
                    min-width: 200px;
                }
            }
        }
    }

    /* Icon */
    .swal2-icon {
        margin-top: 0;
        border: none;

        @media screen and (min-width: 481px) {
            // margin-top: 20px;
        }
        svg, lord-icon {
            width: 63px;
            height: 63px;
            transition: 1s;
            @media screen and (min-width: 481px) {
                width: 93px;
                height: 93px;
            }

            // &:hover {
            //     transform: rotateY(180deg);
            // }
        }

       
    }
     
    /* HTML Container*/
    .swal2-html-container {
        font-size: 16px;
        color: #000;
        margin-left: 1rem;
        margin-right: 1rem;

        a {
            color: #D4574D;
            &:hover {
                text-decoration: underline;
            }
        }
        
        @media screen and (min-width: 481px) {
            font-size: 24px;
        }
    }

    /* Title */
    .swal2-title {
        color: #000;
        font-weight: 700;
        
    }

    &.standardPopup {
        .swal2-actions {
            button {
                &.swal2-confirm {
                    background-color: #d4574d;
                }

                &.swal2-cancel {
                    background-color: #666;
                }
            }
        }
    }


    /* Logout */
    &.logout-prompt {
        div.swal2-html-container {
            font-size: 16px;
            font-weight: 700;
        }
    }

    /* Manage Subscription */
    &.swal-manage-subscription-page {
        // padding: 27px 32px 24px;
        // @media screen and (min-width: 481px) {
        //     padding: 16px 32px 48px;
        // }
    
        &.cancel {
            @media screen and (min-width: 481px) {
                max-width: 563px;
                // padding: 50px 75px 45px;
            }
    
            div.swal2-html-container {  
                div.end-date {
                    padding-bottom: 0;
                    font-size: 14px;
                    line-height: 1;
                    color: #000;
                    @media screen and (min-width: 481px) {
                        font-size: 22px;
                        padding-bottom: 5px;
                    }
                    b { display: block; margin-top: 5px; }
                }
                div.question {
                    margin-bottom: 32px;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 1;
                    color: #000;
                    @media screen and (min-width: 481px) {
                        font-size: 22px;
                    }
                }
                p {
                    font-size: 11px;
                    font-weight: 400;
                    color: #000;
                    @media screen and (min-width: 481px) {
                        font-size: 14px;
    
                        &.sub-content-text {
                            margin: 0 20px;
                        }
                    }
                }
                
            }
    
            .swal2-actions {
                @media screen and (min-width: 481px) {
                    margin-top: 35px;
                    padding: 0 32px;
                }
            }
        }
    
        &.loyalty {
            @media screen and (min-width: 481px) {
                // padding: 50px 75px 45px;
            }
    
            div.title {
                @media screen and (min-width: 481px) {
                    padding-bottom: 32px;
                }
            }
            div.title, p {
                font-size: 14px;
                font-weight: 400;
                line-height: 1;
                color: #000;
    
                @media screen and (min-width: 481px) {
                    font-size: 24px;
                }
            }
    
            .swal2-actions {
                @media screen and (min-width: 481px) {
                    margin-top: 35px;
                    padding: 0 50px;
                }
            }
        }
    
        &.termination, &.isp {
            @media screen and (min-width: 481px) {
                // padding: 50px 75px 45px;
            }
        
            div.swal2-html-container {
                // margin: 0;
                div.question {
                    // padding-top: 38px;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 1;
                    color: #000;
    
                    @media screen and (min-width: 481px) {
                        padding-bottom: 22px;
                        font-size: 22px;
                    }
                }
                select {
                    width: 100%;
                    max-width: 270px;
                    padding: 7px;
                    border: 3px solid #aaa;
                    border-radius: 8px;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 0;
                    font-size: 12px;
                    font-weight: 400;
                    background: url('../../../images/select-arrow-down.svg') no-repeat 97% 50%;
                    -webkit-appearance: none;
                    appearance: none;

                    &:focus, &:active, &:hover {
                        background: url('../../../images/select-arrow-down.svg') no-repeat 97% 50%;
                    }
    
                    @media screen and (min-width: 481px) {
                        margin-bottom: 24px;
                        font-size: 20px;
                        padding: 8px;
                        max-width: 399px;
                    }
                }
            }
    
            .swal2-actions {
                @media screen and (min-width: 481px) {
                    margin-top: 11px;
                    padding: 0 50px;
                }
            }
        }
    
        &.sorry {
            div.swal2-html-container {

                div.title {
                    padding: 18px 0;
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 1;
                    color: #000;

                    @media screen and (min-width: 481px) {
                        font-size: 22px;
                    }
                }
                div.swal-icon {
                    margin-bottom: 28px;
                }
                p {
                    font-size: 12px;
                    font-weight: 400;
                    color: #000;

                    @media screen and (min-width: 481px) {
                        font-size: 20px;
                    }
                }
            }
        }
    
        &.subscriptionnotif-success {
            .swal2-title {
                padding-top: 33px;
                font-size: 20px;
                font-weight: 700;
                @media screen and (min-width: 481px) {
                    padding-top: 16px;
                    font-size: 24px;
                }
            }
            .swal2-html-container {
                font-size: 14px;
                margin-top: 13px;

                @media screen and (min-width: 481px) {
                    margin: 27px 0 0;
                    font-size: 20px;
                }
            }
    
            .swal2-actions {
                padding: 0 5%;
                margin-top: 33px;
    
                @media screen and (min-width: 481px) {
                    padding: 0 44px;
                }
                button {
                    width: 100%;
                    max-width: 274px;
                    font-size: 16px;
    
                    @media screen and (min-width: 481px) {
                        font-size: 20px;
                        max-width: 327px;
                    }
                }
            }
        }
    
        &.error-cancel {    
            .swal2-html-container {
                font-weight: 700;
            }

            .swal2-icon {
                .swal2-icon-content{
                    svg {
                        path {
                            fill: #d4574d;
                        }
                    }
                }
            }
    
            @media screen and (max-width: 480px) {
                .swal2-html-container {
                    font-size: 14px;
                    margin-top: 0;
                }
            }
        }
    
        &.vaspopup {
            background: linear-gradient(
                to bottom,
                #FFD9D6 0,
                #FFD9D6 106px,
                #ffffff 106px,
                #ffffff 100%
            );
            @media screen and (min-width: 481px) {
                background: linear-gradient(
                to bottom,
                #FFD9D6 0,
                #FFD9D6 134px,
                #ffffff 134px,
                #ffffff 100%
            );
            }

            .vaspopup-closebtn {
                background-color: #D4574D;
                color: #fff;
                width: 24px;
                height: 24px;
                font-size: 1.5em;
                padding-bottom: 2px;
                margin-top: -10px;
                margin-right: 6%;
                
                @media screen and (min-width: 481px){
                    width: 31px;
                    height: 31px;
                    font-size: 2em;
                    padding-bottom: 3px;
                    margin-right: 3%;
                }
            }

            &.one-voice {
                @media screen and (min-width: 481px) {
                    max-width: 456px;
                }
            }

            &.securenet {
                max-width: 95%;
                @media screen and (min-width: 481px) {
                    max-width: 600px;
                }
                .vaspopup-content {
                    .vaspopup-inner {
                        .two-col {
                            display: flex;
                            justify-content: space-around;

                            @media screen and (min-width: 481px) {
                                justify-content: space-between;
                            }
                        }

                        .col-inner {
                            padding-left: 0;
                            margin: 0 auto;

                            &.right {
                                min-width: 200px;

                                @media screen and (min-width: 481px) {
                                    // padding-left: 11px;
                                    min-width: 280px;
                                    margin-right: 0;
                                }
                                @media screen and (min-width: 600px) {
                                    margin-right: 30px;
                                    margin-left:0px;
                                    min-width: 270px;
                                    // margin-left:0;
                                }
                            }
                           
                            @media screen and (max-width:415px) {
                                &.left {
                                    margin-left: 0;
                                    min-width: 50%;
                                    padding-left: 10%;
                                }
                                &.right {
                                    margin-left: 0.5rem;
                                    margin-right: 0;
                                    min-width: 50%;
                                }
                            }

                            
                            &.left {
                                .MuiFormControl-root {
                                    margin-bottom: 14px;
                                    @media screen and (min-width: 481px) {
                                        margin-bottom: 20px;
                                    }
                                }
                            }
                            .MuiFormControl-root {
                                font-size: 14px;
                                @media screen and (min-width: 481px) {
                                    font-size: 20px;
                                }

                                .MuiFormLabel-root {
                                    font-size: 14px;
                                    font-weight: 700;
                                    display: flex;
                                    align-items: center;
                                    -webkit-transform: translateZ(0);
                                    -webkit-backface-visibility: hidden;

                                    svg {
                                        margin-left: 0.3rem;
                                        margin-top: 0;
                                        width: 16px;
                                        height: 16px;

                                        @media screen and (min-width: 481px) {
                                            margin-top: 0.1rem;
                                            margin-left: 0.4rem;
                                            width: 18px;
                                            height: 18px;
                                        }
                                    }

                                    @media screen and (min-width: 481px) {
                                        font-size: 20px;
                                    }
                                }
    
                                .MuiSwitch-track {
                                    width: 34px;
                                    height: 18px;
                                    @media screen and (min-width: 481px) {
                                        width: 64px;
                                        height: 32px;
                                    }
    
                                    .MuiSwitch-thumb {
                                        width: 16px;
                                        height: 16px;
                                        left: 10px;
                                        @media screen and (min-width: 481px) {
                                            width: 24px;
                                            height: 24px;
                                            left: 16px;
                                        }
                                    }
                                }
                            }
                        }
        
                        &.vas-securenet {
                            font-size: 20px;
                            padding-bottom: 50px;

                            @media screen and (max-width: 480px){
                                padding-left: 5%;
                                padding-right: 5%;
                            }
                            
                            .form-radiogroup {
                                margin: 20px 0 0;
                                .radiowith-tooltip-wrap {
                                    display: flex;
                                    margin: 7px 0;
                                    align-items: center;
                                    
                                    @media screen and (min-width: 481px) {
                                        margin: 6px 0;
                                    }
                                    svg {
                                        width: 16px;
                                        height: 16px;
                                    }
        
                                    .MuiRadio-root {
                                        margin-right: 6px;
                                        margin-top: 0;
        
                                        .MuiRadio-radio{
                                            border-width: 3px;
                                        }
                                    }
                                }
        
                                .MuiRadio-root {
                                    text-align: left;
                                    label {
                                        display: flex;
                                        align-items: center;
                                        font-weight: 500;
                                        margin-bottom: 0;
                                        font-size: 13px;
                                        margin-left: -3px;
                                        color: #3f3f3f;
        
                                        @media screen and (min-width: 481px) {
                                            font-size: 18px;
                                            margin-left: 0;
                                        }
                                    }
                                }
                            }
        
                            .form-switch, .custom-switch {
                                text-align: left;
                                height: 24px;
                                display: flex;
                                align-items: center;
                                width: fit-content;
                                padding-left: 3rem;
                                position: relative;

                                @media screen and (min-width: 481px) {
                                    height: 40px;
                                    padding-left: 5rem;
                                }

                                .custom-control-input:checked ~ .custom-control-label {

                                    &::after{
                                        transform: translateX(1.11rem);
                                        -webkit-transform: translateX(1.11rem);
                                        background-color: #fff;
                                        -webkit-backface-visibility: hidden;

                                        @media screen and (min-width: 481px) {
                                            transform: translateX(2.25rem);
                                            -webkit-transform: translateX(2.25rem);
                                        }
                                    }

                                    &::before {
                                        background: #149635;
                                    }
                                }
        
                                .custom-control-label{  
                                    // width: 34px;
                                    height: 18px; 
                                    position: static;
                                    text-align: right;
                                    width: 100%;
                                    line-height: 1rem;

                                    @media screen and (max-width: 480px) {
                                        font-size: 15px;
                                        line-height: 1.5rem;
                                    }

                                    &::before{
                                        // height: inherit;
                                        border-radius: 32px;
                                        background: #d4574d;
                                        left: 0;
                                        width: 2.5rem;
                                        height: 1.3rem;
                                        top: 0.18rem;

                                        @media screen and (min-width: 481px) {
                                            width: 70px;
                                            height: 32px;
                                        }

                                        

                                    }
        
                                    &::after {
                                        top: 0.34rem;
                                        left: 0.2rem;
                                        width: 16px;
                                        height: 16px;
                                        background-color: #fff;
                                        animation: none;
        
                                        @media screen and (min-width: 481px) {
                                            top: 0.33rem; 
                                            left: 0.18rem;
                                            width: 26px;
                                            height: 26px;
                                        }
                                    }
                                }
                            }
        
                            .form-select {
                                width: 100%;
                                min-width: 119px;
                                border: 3px solid #A9A9A9;
                                border-radius: 10px;
                                padding: 7px;
                                font-size: 14px;
                                color: #646464;
                                height: 100%;
                                line-height: 1;
                                background: url('../../../images/select-arrow-down.svg') no-repeat 97% 50%;
                                -webkit-appearance: none;
                                appearance: none;
        
                                &:focus, &:active, &:hover {
                                    background: url('../../../images/select-arrow-down.svg') no-repeat 97% 50%;
                                }
        
                                @media screen and (min-width: 481px) {
                                    min-width: 212px;
                                    font-size: 18px;
                                    padding: 8px; 
                                }
                            }
                        }
                    }
        
                    .col-buttons {
                        display: flex;

                        @media screen and (min-width: 481px) {
                            justify-content: space-around;
                        }
        
                        .btn {
                            font-weight: 700;
                            margin-bottom: 10px;
                            margin: 0 auto;
                            max-width: 100%;
                            border-radius: 30px;
                            font-size: 16px;
                            min-width: 124px;
                            padding: 5px 8px;

                            @media screen and (min-width: 481px) {
                                font-size: 24px;
                                min-width: 234px;
                                padding: 8px 12px;
                            }
        
                            &.cancel-btn {
                                background-color: #676767 !important;
                                border-color: #676767 !important;
                                &:hover {
                                    background-color: #676767 !important;
                                }
                            }
        
                            &.update-btn {
                                background-color: #D4574D !important;
                                border-color: #D4574D !important;
                                &:hover {
                                    background-color: #D4574D !important;
                                }
                            }  
                        }
                    }
                }
            }

            .vaspopup-content {
                margin-left: 0;
                margin-right: 0;
                margin-top: 0;
                @media screen and (min-width: 481px) {
                    margin-top: 18px;
                }
                .vaspopup-title {
                    font-size: 18px;
                    margin: 10px auto 10px;
    
                    @media screen and (min-width: 481px) {
                        margin: 16px auto 10px;
                        font-size: 24px;
                    }
                }
    
                .vaspopup-inner {
                    margin-top: 10px;
                    overflow-x: hidden;
                    @media screen and (min-width: 481px) {
                        margin-top: 22px;
                    }
                    
                    .btn {
                        border-radius: 26px;
                        border: 2px solid #D4574D;
                        font-size: 16px;
                        padding: 6px 18px;
                        font-weight: 700;
                        @media screen and (min-width: 481px) {
                            padding: 13px 32px;
                            font-size: 20px;
                        }
                        &:hover {
                            background-color: #D4574D;
                        }
                    }
                }
            }
    
            .swal2-actions {
                margin-top: 13px;
                @media screen and (min-width: 481px) {
                    margin-top: 39px;
                }
                .unsubscribe-btn {
                    // padding-left: 56px;
                    // padding-right: 56px;
                    width: 100%;
                    max-width: 152px;
                    padding: 5px 8px;
                    @media screen and (min-width: 481px) {
                        max-width: 222px;
                        padding: 8px 12px;
                    }
                }
            }
        }
    
        &.vasUnsubscribe {
            max-width: 487px;
    
            .vasUnsubscribe-content {
    
                .vasUnsubscribe-text {
                    margin-bottom: 20px;
    
                    p {
                        font-size: 16px;
                        margin: 0;
                        @media screen and (min-width: 481px) {
                            font-size: 22px;
                        }
                    }
                }
            }
    
            .vasUnsubscribe-action-btn {
                padding:0 33px;
    
                button {
                    min-width: 120px;
                    outline: none;
                }
            }
        }
    
        &.vasUnsubscribeConfirm {

            .vasUnsubscribeConfirm-content {
                margin-top: 0;
                @media screen and (min-width: 481px) {
                    margin-top: 18px;
                }
                p {
                    font-size: 16px;
                    @media screen and (min-width: 481px) {
                        font-size: 20px;
                    }
                    strong, b {
                        font-weight: 700;
                    }
                }
    
                .agreeTerms {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-top: 37px;
                    margin-bottom: 11px;
                    font-size: 14px;
                    @media screen and (min-width: 481px) {
                        font-size: 18px;
                    }
                    .agree-checkbox {
                        margin-right: 10px;
                        @media screen and (min-width: 481px) {
                            margin-right: 12px;
                        }
                        .MuiCheckbox-checkbox {
                            // width: 15px;
                            // height: 15px;
                            border-width: 2px;
    
                            // &.Joy-checked {
                            //     svg {
                            //         width: 0.7em;
                            //         height: 0.7em;
                            //     }
                            // }
    
                            @media screen and (min-width: 481px) {
                                width: 20px;
                                height: 20px;
                                border-width: 3px;
    
                                &.Joy-checked {
                                    svg {
                                        width: 1em;
                                        height: 1em;
                                    }
                                }
                            }
                        }
                    }
    
                    a {
                        color: #D4574D;
    
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
    
            .vasUnsubscribeConfirm-action-btn {
                @media screen and (min-width: 481px) {
                    padding:0 32px;
                }
            }

            .vaspopup-promo-btns {
                @media screen and (min-width: 481px) {
                    padding:0;
                }
            }
        }
    
        &.subscribepopup {
            .subscribe-content {
                p {
                    font-size: 14px;

                    @media screen and (min-width: 481px) {
                        font-size: 20px; 
                    }
    
                    &.monthlyCharges {
                        margin-top: 0;

                        @media screen and (min-width: 481px) {
                            margin-top: 34px;
                        }
                    }
                }
                strong {
                    color: #000;
                }
            }
    
            .subscribe-action-btn {
                padding:0 33px;
            }
        }
    
        &.unsubscribepopup, &.subscribepopup-success {  
            .swal2-title{
                font-weight: 700;
                font-size: 24px;
                color: #000; 
            }
            
            @media screen and (max-width: 480px) {
                .swal2-title{
                    font-size: 16px;
                    padding-top: 0;
                }
    
                .swal2-html-container {
                    font-size: 14px;
                    margin-left: 1.6rem;
                    margin-right: 1.6rem;
                }                
            }
        }

        &.relocation-popup, &.temp-suspension-popup, &.transfer-ownership-popup {
            .agreeterms-content {
                overflow: hidden;
                
                * {
                    font-size: 16px;
    
                    @media screen and (min-width: 481px) {
                        font-size: 24px
                    }
                }

                .agreeTerms {
                    padding-top: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .agree-checkbox {
                        margin-right: 10px;
                    }
                }
            }
        }
    
    }

    /* Edit Credit Card */
    &.swal-edit-card-page {
        @media screen and (min-width: 481px) {
            // max-width: 563px;
        }

        .swal2-title {
            padding: 0;
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;
            color: #000;
            @media screen and (min-width: 481px) {
                padding: 12px 16px 0;
                margin-bottom: 0;
                font-size: 24px;
            }
        }
    
        .swal2-html-container {
            font-size: 16px;
            color: #000;
    
            @media screen and (min-width: 481px) {
                margin: 24px 38px 7px;
                font-size: 24px;
            }
        }
    
        .swal2-actions {
            width: 100%;
            padding: 0;
            @media screen and (min-width: 481px) {
                padding: 0 45px;
            }

        }
    
        &.error-popup {
            .swal2-icon {
                svg{
                    path {
                        fill: #d4574d;
                    }
                }
            }
        }
    }

    /* Manage Credit Card*/
    &.manage-card-popup, 
    &.manage-card-success-popup {
        @media screen and (min-width: 481px){
            max-width: 563px;
        }

        .swal2-html-container {
            font-size: 16px;
            @media screen and (min-width: 481px) {
                margin: 24px 38px 7px;
                font-size: 24px;
            }
        }

        .swal2-title {
            padding: 0;
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;

            @media screen and (min-width: 481px) {
                padding: 12px 16px 0;
                margin-bottom: 0;
                font-size: 24px;
            }
        }
    }

    .manage-card-success-popup {
        .swal2-html-container {
            margin: 0;

            @media screen and (min-width: 481px) {
                margin-top: 22px;
                margin-bottom: 22px;
            }
        }
    }

    /* Add Card */
    &.add-card-popup {
        @media screen and (min-width: 481px) {
            max-width: 563px;
        }

        .swal2-title {
            padding: 0;
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;

            @media screen and (min-width: 481px) {
                padding: 12px 16px 0;
                margin-bottom: 0;
                font-size: 24px;
            }
        }

        .swal2-html-container {
            font-size: 16px;
            @media screen and (min-width: 481px) {
                margin: 24px 38px 7px;
                font-size: 24px;
            }
        }

        div.swal2-actions {
            padding: 0 80px;
        }
    }

    &.addcard-notifpopup {
        .swal2-title {
            font-size: 16px;
            padding: 0 72px;
        }
    
        .addcard-notifpopup-content {
    
            .card-icon {
                svg {
                    width: 61px;
                    height: 40px;
                }
            }
    
            .card-text {
                margin-left: 5px;
                font-weight: 700;
                font-size: 20px;
                color: #B4B4B4;
            }
        }
    
        .addcard-notifpopup-btn {

            button {
                min-width: 120px;
                outline: none;
            }
        }
    }

    &.invoice-payment-popup {
        @media screen and (min-width: 481px) {
            padding: 50px 20px 48px;
        }
        
        .success {
            p {
                font-size: 20px;
                font-weight: 700;
                margin: 0;
                @media screen and (min-width: 481px){
                    font-size: 24px;
                }
                
            }
        }
    
        .error {
            h2 {
                font-size: 20px;
                margin-bottom: 10px;
                @media screen and (min-width: 481px){
                    font-size: 24px;
                    margin-bottom: 20px;
                }
            }

            p {
                font-size: 14px;
                margin-bottom: 0;

                @media screen and (min-width: 481px){
                    font-size: 16px;
                }
            }
        }

        .swal2-icon {
            margin: 0 auto;
            margin-bottom: 18px;

            @media screen and (min-width: 481px) {
                margin: 26px auto 10px;
            }

            &.invoice-payment-icon { /* for makepayment popups */
                border: 0;
                margin-bottom: 0;

                svg {
                    width: 99px;
                    height: 99px;

                    @media screen and (min-width: 481px) {
                        width: 159px;
                        height: 159px;
                    }
                }
            }
        }
    
        .swal2-title {
            padding: 0;
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 700;
    
            @media screen and (min-width: 481px) {
                padding: 12px 16px 0;
                margin-bottom: 0;
                font-size: 24px;
            }
        }
    
        .swal2-html-container {
            margin: 10px 0 0;
            font-size: 16px;
            color: #000;
            * {
                color: #000;
            }

            @media screen and (min-width: 481px) {
                margin: 33px 38px 7px;
                font-size: 24px;
            }
        }
        
        &.addcard-notifpopup {    
            .swal2-title {
                padding: 0 18px;
                font-size: 15px;
                font-weight: 700;
                margin-top: 18px;
                @media screen and (min-width: 481px) {
                    padding: 0;
                    margin-top: 0;
                }
            }
    
            .swal2-html-container {
                .card-icon {
                    svg {
                        width: 61px;
                        height: 40px;
                        @media screen and (min-width: 481px) {
                            width: 78px;
                            height: 50px;
                        }
                    }
                }
            }

            .addcard-notifpopup-btn {

                button {
                    @media screen and (max-width: 480px){
                        min-width: 104px;
                    }
                }
            }
        }
    }

    /* New Enquiry */
    &.new-enquiry-success {

        .swal2-title {
            font-size: 24px;
        }

        .swal2-html-container {
            padding: 16px 48px;
            margin: 0;
            font-size: 14px;
            line-height: 1.5;
            @media screen and (min-width: 481px) {
                font-size: 20px;
                line-height: 1;
            }
        }
        .swal2-actions {
            font-size: 16px;
            line-height: 1;
            
            @media screen and (min-width: 481px) {
                font-size: 20px;
                padding: 0 20px;
            }
            button {
                padding: 12px 18px;
                &.swal2-confirm {
                    width: 209px;
                }
            }
        }
    }

    &.ohno-popup {

        @media screen and (min-width: 481px) {
            max-width: 868px !important;
        }

        .swal2-actions {
            padding: 0 15px;
            padding-top: 10px; 

            @media screen and (min-width: 481px){
                justify-content: flex-end;
                padding-top: 0;
            }
            
            .swal2-confirm {
                margin: 0;
                width: 188px;
            }
        }

        .error-with-form {
            margin-top: 60px;

            @media screen and (min-width: 481px) {
               margin-top: 70px;
            }

            div.d-flex {
                flex-flow: column wrap;
                @media screen and (min-width: 481px) {
                    flex-flow: row;
                }
            }

            .left {
                @media screen and (min-width: 481px){
                    margin-right: 90px;
                }

                h2 {
                    color: #d4574d;
                    margin-top: 10px;

                    @media screen and (min-width: 481px){
                        font-size: 40px;
                        margin-top: 23px;
                    }
                }

                p {
                    color: #000;
                    @media screen and (min-width: 481px){
                        font-size: 20px;
                    }
                }

                svg{
                    @media screen and (min-width: 481px){
                        width: 114px;
                        height: 115px;
                    }
                }
            }

            .right {
                .form-group {
                    text-align: left;
                    margin-bottom: 10px;
                    @media screen and (min-width: 481px){
                        margin-bottom: 1.5rem;
                    }

                    .form-label {
                        color: #4A4A4A;
                        font-size: 13px;
                        margin-bottom: 0.4rem;
                    }
                    
                    input, textarea {
                        border: 1px solid #A9A9A9;
                        border-radius: 10px;
                    }

                    input {
                        height: 37px;
                        padding: 0 15px;
                    }

                    textarea {
                        padding: 10px 15px;
                    }
                }
            }
            .col-inner {
                width: 100%;
                @media screen and (min-width: 481px) {
                    width: 50%;
                }

            }
        }
    }

}
