// .buttons-container {
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;

//     a.back-button {
//         color: #676767;
//     }
// }

// .buttons-divider{

//     height: 33px;
//     margin: 0 20px;
//     overflow: hidden;

//     &::before {
//         content: "";
//         border: 1px solid #676767;
//         height: 100%;
//         display: inline-block;
//         line-height: 1;
//     }
// }

.button-container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 1;
    @media screen and (min-width: 481px) {
        justify-content: flex-end;
    }
    .go-back-link {
        font-weight: 700;
        color: #666;
    }
    .vertical-divider {
        display: flex;
        height: 32px;
        padding: 0 24px;
        position: relative;
        justify-content: center;
        div {
            height: 100%;
            position: absolute;
            border-right: 2px solid #666;
        }
    }
    button {
        padding: 10px 30px;
        border-radius: 100px;
        border: none;
        background-color: #d4574d;
        color: #fff;
    }
}

.is-invalid {
    border-color: #d4574d !important;
}

.form-error {
    color: #d4574d;
    margin: 0;
}

.my-edit-card {

    .form-error {
        min-height: 13px;
    }
}

// .swal-edit-card-page {
//     padding: 32px;
//     border-radius: 30px;
//     @media screen and (min-width: 481px) {
//         padding: 10px 20px 48px;
//         max-width: 563px;
//     }
//     .swal2-icon {
//         margin: 0 auto;
//         margin-bottom: 18px;
//         border: none;

//         @media screen and (min-width: 481px) {
//             margin: 40px auto 10px;
//         }
//         svg {
//             width: 63px;
//             height: 63px;
            
//             @media screen and (min-width: 481px) {
//                 width: 93px;
//                 height: 93px;
//             }
//         }
//     }

//     .swal2-title {
//         padding: 0;
//         margin-bottom: 10px;
//         font-size: 16px;
//         font-weight: 700;

//         @media screen and (min-width: 481px) {
//             padding: 12px 16px 0;
//             margin-bottom: 0;
//             font-size: 24px;
//         }
//     }

//     .swal2-html-container {
//         margin: 0;
//         font-size: 16px;

//         a {
//             color: #D4574D;
//             &:hover {
//                 text-decoration: underline;
//             }
//         }

//         @media screen and (min-width: 481px) {
//             margin: 24px 38px 7px;
//             font-size: 24px;
//         }
//     }

//     .swal2-actions {
//         width: 100%;
//         padding: 0;
//         @media screen and (min-width: 481px) {
//             padding: 0 45px;
//         }

//         button {
//             margin: 0 auto;
//             border-radius: 30px;
//             box-shadow: none;
//             font-size: 16px;
//             font-weight: 700;
//             padding-top: 8px;
//             padding-bottom: 8px;
//             min-width: 104px;
//             outline: none;

//             @media screen and (min-width: 481px) {
//                 min-width: 122px;
//                 font-size: 24px;
//             }
            
//             &:hover, &:active, &:focus {
//                 box-shadow: none;
//             }
//         }
//     }

//     &.error-popup {
//         @media screen and (min-width: 481px) {
//             max-width: 487px;
//         }

//         .swal2-icon {
//             svg{
//                 path {
//                     fill: #d4574d;
//                 }
//             }
//         }
//     }
// }