.community-forum-wrapper {
    padding: 0 20px;
    margin: 0;
    @media screen and (min-width: 481px) {
        padding: 0;
    }
    > .col {
        padding: 0;
    }

    .content-wrapper {
      width: 100%;
      // max-width: 856px;
      margin: 0 auto;

      @media screen and (min-width: 640px) { 
        width: 75%;
      }
      @media screen and (min-width: 992px) { 
        width: 45%;
      }
       
    }

    .card, .discourse-loading {
      padding: 20px 8px;

      @media screen and (min-width: 640px) {
          padding: 32px;
      }

      border-radius: 1.25rem;
    }

    .discourse-loading {
      background-color: #e3e3e3;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      
      @media screen and (min-width: 481px) {
        height: 228px;
      }

      p {
        font-size: 1.2rem;
        font-weight: 600;

        @media screen and (min-width: 640px){
          font-size: 1.5rem;
        }
      }
    }

    div.card {
        
        background-color: #FFD9D6;

        div.card-body {
            form {
              width: 80%;
              margin: 0 auto;
                div.row {
                    div.col-detail {
                        display: flex;
                        align-items: flex-start;
                        @media screen and (min-width: 481px) {
                            align-items: center;
                        }
                        div.icon-container {
                            display: none;
                            justify-content: center;
                            align-items: center;
                            width: 100%;
                            max-width: 48px;
                            height: 48px;
                            border-radius: 6px;
                            margin-right: 12px;
                            margin-bottom: 16px;
                            background-color: #d4574d;
                            @media screen and (min-width: 481px) {
                                display: flex;
                            }
                            svg {
                                width: 29px;
                                height: 29px;

                                path {
                                    fill: #FFD9D6;
                                }
                            }
                        }
                        div.form-group {
                            width: 100%;
                            label.form-label {
                                margin: 0 0 5px;
                                font-size: 0.9rem;
                                line-height: 1; 

                                &.label-with-info {
                                  svg {
                                      margin-left: 6px;
                                      width: 0.9rem;
                                      height: 0.9rem;
                                      align-self: self-start;
                                  }
                              }
                            }
                            input.form-control, div.user-detail {
                                width: 100%;
                                height: auto;
                                padding: 4px 12px;
                                border-radius: 6px;
                                border: 1px solid #aaa;
                                margin: 0;
                                background-color: #fff;
                                font-size: 18px;
                                font-weight: 700;
                                // line-height: 1;
                                color: #666;

                                @media screen and (max-width: 600px) {
                                    font-size: 16px;
                                }

                                &:disabled, &.disabled  {
                                    background-color: #e5e3e3;
                                    pointer-events: none;
                                }
                                
                            }
                            div.user-detail {
                                padding: 0;
                                border: 1px solid transparent;
                                background-color: transparent;
                            }
                            input[type=password] {
                              &::placeholder {
                                font-size: 0.6rem;
                              }
                            }
                        }
                    }
                }
            }

            .form-title {
              text-align: center;
              margin-bottom: 1.5rem;
            }

            .form-footer{
              margin: 4rem auto 0;
              width: 120px;
              text-align: center;
              filter: grayscale(1);
              opacity: 0.5;
              
            }
        }
    }
    
    .button-container {
      justify-content: center;
      // @media screen and (min-width: 481px) {
      //     justify-content: flex-end;
      // }
    
      button {
        width: 50%; 
    
        &:disabled {
          color: #dcdcdc;
          background-color: #ada9a9;
        }
      }
    }

    .form-helper-links {
      text-align: center;
      margin-top: 2.5rem;

      a{
        color: #373a3c;
        text-decoration: underline;

        &:hover {
          color: #000;
        }
      }
    }
    
}

.swal-profile {
    svg, path {
        fill: #d4574d;
    }
}

.tooltip, [id^=tooltip-] {
  font-size: 12px;

  .tooltip-inner {
      background-color: #666;
      text-align: left;

      @media screen and (min-width: 992px){
          max-width: 300px;
      }
      @media screen and (max-width: 480px){
          max-width: 150px;
      }

      ul {
        font-size: 0.75rem;
        padding-left: 1.2rem;
        margin: 10px;
      }
  }
  &[x-placement^=right] .arrow::before { 
      border-right-color: #666;
      border-bottom-color: transparent;
  }
}