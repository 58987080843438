#zuora_payment {
    iframe {
        background: none !important;
        border: none !important;
        overflow: hidden !important;
        // min-height: 500px;
    }
}

.addCard-page {
    // padding: 0 20px;
    // margin-left: 0;
    // margin-right: 0;
    // @media screen and (min-width: 481px) {
    //     padding: 0;
    // }
    #zuora_payment {
        iframe {
            width: 100%;
            background: none !important;
            border: none !important;
            overflow: hidden !important;
            // min-height: 500px;
            display: block;
        }
    }
    
    .form-container {
        position: relative;
    
        #zoura_payment {
            z-index: 0;
            position: relative;
        }
    
        .button-container {
            position: absolute;
            bottom: 0;
            width: auto;
            height: auto;
            right: 175px;
            z-index: 1;
        }
    }
}

