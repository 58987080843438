.invoice-table {

    tbody {
        tr {
          &:first-child td {
            padding-top: 29px !important;
          }          
        }
    }

    td.invoicenum{

        svg {
            margin-left: 5px;
            width: 18px;
            height: 18px;
        }
    }

    .invoicestatus {
        font-size: 18px !important;
        border-radius: 9px;
    }
}

.top-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .badge {
        font-size: 15px;
        font-weight: 400;
        padding: 7px 16px;
        color: #fff;
        height: 31px;

        svg {
            margin-left: 5px;
        }
    }

    .refresh-button {
        border-radius: 50%;
        width: 42px;
        height: 42px;
        overflow: hidden;
        background-color: #D4574D;
        position: relative;
        text-align: center;
        border: none;

        &:hover, &:focus, &.focus {
            background-color: rgba(212, 87, 77, 0.8);
        }

        svg {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;

        }
    }
}

.orDivider {
    display: flex;
    padding: 14px 24px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    > div {
        padding: 0 8px;
    }
    hr {
        width: 100%;
        margin: 0 auto;
        margin-top: 6px;
        border-top: 2px solid #d4574d;
    }

}

.payment-card-wrapper {
    .card-title {
        font-size: 16px;
        color: #fff;
        background-color: #D4574D;
        border-radius: 30px;
        display: inline-block;
        padding: 13px 22px;
    }

    .payment-card-opt {
        background-color: #FCFBFE;
        border: 4px solid #D4574D;
        border-radius: 20px;
        text-align: center;
        padding: 22px 0 17px;
        color: #D4574D;
        margin-bottom: 13px;

        &.clickable {
            cursor: pointer;
        }

        &:last-child {
            margin-bottom: 0;
        }

        h5 {
            color: #D4574D;
            margin-bottom: 5px;
            font-size: 20px;
            line-height: 24px;
        }

        p {
            margin-bottom: 0;
        }

        .qr-content {
            img {
                display: block;
                margin: 0 auto;
                image-rendering: pixelated;
                image-rendering: -webkit-optimize-contrast;
            }

            ol {
                display: inline-block;
                padding-left: 0px;
                text-align: left;
                margin: 0 auto;
            }

            .download-paynowqr {
                width: 70%;
                margin: 10px auto 0;
                border-radius: 30px;
                font-size: 16px;
                background-color: #D4574D;
                color: #fff;
                padding: 0;
                // padding: 8px 0;
                border: 0;
            }
        }

        &.payment-opt-cc {
            padding-left: 20px;
            padding-right: 20px;

            .add-card-form {
                // height: 100%;
                margin: 0 6px 10px;
                overflow: hidden;
                iframe {
                    min-height: 100%;
                    // margin: 0 auto;
                    // // height: 451px;
                    // display: block !important;
                }
            }

            .media-list {
                margin-top: 12px;

                .media {
                    margin-bottom: 12px;
                    &.amount-field {
                        label {
                            font-size: 12px;
                            margin-bottom: 3px;
                            color: #000;
                        }

                        input {
                            width: 100%;
                            border-radius: 10px;
                            padding: 5px 12px;
                            border: 2px solid #A9A9A9;

                            /* Remove spinner */
                            /* Chrome, Safari, Edge, Opera */
                            &::-webkit-outer-spin-button,
                            &::-webkit-inner-spin-button {
                                -webkit-appearance: none;
                                margin: 0;
                            }
                            /* Firefox */
                            &[type=number] {
                                -moz-appearance: textfield;
                            }
                        }
                    }

                    &.radio-opt {
                        > * {
                            margin-right: 4px;
                            color: #000;
                            cursor: pointer;
                        }

                        .card-icon {
                            margin-right: 4px;
                            svg {
                                width: 23px;
                                height: 15px;
                            }
                        }

                        label {
                            margin-bottom: 1px;
                        }
                    }

                    &.add-card {
                        color: #4680FF;
                        cursor: pointer;
                    }

                    &.btn-proceed-pay {
                        button {
                            background-color: #D4574D;
                            border-radius: 20px;
                            padding: 8px 15px;
                            color: #fff;
                            border: none;
                            font-weight: 600;

                            &:hover, &:focus {
                                background-color: #c65048;
                            }
                        }
                    }
                }
            }
        }


    }
}

.current-bal-row {
    position: absolute;
    right: 0;
    top: -100px;
    width: 20%;
}

.current-bal-content {
    text-align: center;
    background-color: #272727;
    border-radius: 15px;
    padding: 11px 19px;
    margin-bottom: 23px;
    * {
        font-size: 20px;
        color: #fff;
        display: inline-block;
        margin: 0;
    }

}

.invoicepaymentpopup {
    .swal2-icon, .swal2-icon-show {
        border: 0;
    }
}

// .addcard-notifpopup {
//     width: 100%;
//     max-width: 487px;
//     .swal2-title {
//         font-size: 16px;
//         padding: 0 72px;
//     }

//     .addcard-notifpopup-content {

//         .card-icon {
//             svg {
//                 width: 61px;
//                 height: 40px;
//             }
//         }

//         .card-text {
//             margin-left: 5px;
//             font-weight: 700;
//             font-size: 20px;
//             color: #B4B4B4;
//         }
//     }

//     .addcard-notifpopup-btn {
//         padding:0 !important;
//         @media screen and (min-width: 481px) {
//             padding:0 33px !important;
//         }
//         button {
//             min-width: 120px;
//             outline: none;
//         }
//     }
// }

// .invoice-payment-popup {
//     width: 100%;
//     padding: 32px;
//     border-radius: 30px;

//     @media screen and (min-width: 481px) {
//         padding: 50px 20px 48px;
//         max-width: 487px;
//     }
    
//     .success {
//         p {
//             font-size: 24px;
//             font-weight: 700;
//         }
//     }

//     .error {
//         h2 {
//             font-size: 24px;
//             margin-bottom: 20px;
//         }

//         p {
//             font-size: 16px;
//         }


//     }

//     .invoice-payment-icon {
//         border: 0;
//     }

//     .swal2-icon {
//         margin: 0 auto;
//         margin-bottom: 18px;
//         border: none;

//         @media screen and (min-width: 481px) {
//             margin: 40px auto 10px;
//         }
//         svg {
//             width: 63px;
//             height: 63px;
            
//             @media screen and (min-width: 481px) {
//                 width: 93px;
//                 height: 93px;
//             }
//         }
//     }

//     .swal2-title {
//         padding: 0;
//         margin-bottom: 10px;
//         font-size: 16px;
//         font-weight: 700;

//         @media screen and (min-width: 481px) {
//             padding: 12px 16px 0;
//             margin-bottom: 0;
//             font-size: 24px;
//         }
//     }

//     .swal2-html-container {
//         margin: 0;
//         font-size: 16px;

//         a {
//             color: #D4574D;
//             &:hover {
//                 text-decoration: underline;
//             }
//         }

//         @media screen and (min-width: 481px) {
//             margin: 24px 38px 7px;
//             font-size: 24px;
//         }
//     }

//     .swal2-actions {
//         width: 100%;
//         padding: 0;
//         @media screen and (min-width: 481px) {
//             padding: 0 45px;
//         }

//         button {
//             margin: 0 auto;
//             border-radius: 30px;
//             box-shadow: none;
//             font-size: 16px;
//             font-weight: 700;
//             padding-top: 8px;
//             padding-bottom: 8px;
//             min-width: 104px;
//             outline: none;

//             @media screen and (min-width: 481px) {
//                 min-width: 122px;
//                 font-size: 24px;
//             }
            
//             &:hover, &:active, &:focus {
//                 box-shadow: none;
//             }
//         }
//     }

//     &.addcard-notifpopup {
        
//         @media screen and (min-width: 481px) {
//             padding: 50px 20px 48px !important;
//             max-width: 487px;
//         }

//         .swal2-title {
//             padding: 0;
//             font-size: 15px;
//             font-weight: 700;
//         }

//         .swal2-html-container {
//             .card-icon {
//                 svg {
//                     width: 61px;
//                     height: 40px;
//                     @media screen and (min-width: 481px) {
//                         width: 78px;
//                         height: 50px;
//                     }
//                 }
//             }
//         }
//     }
// }