/**  =====================
      Authentication css start
==========================  **/
div.auth-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
    min-height: 100vh;
    padding-bottom: 32px;
    border-bottom: 6px solid #e43b44;
    background-color: #fff;
    overflow: hidden;

    @media screen and (max-width: 480px) {
        justify-content: space-around;
    }


    div.auth-header {
        width: 100%;
        margin-bottom: 0.5rem;

        @media screen and (min-width: 641px) {
            margin-bottom: 1.5rem;
        }

        // img#main-logo {
        //     width: 100%;
        //     max-width: 192px;
        // }
    }

    div.auth-content {
        width: 100%;
        max-width: 320px;
        padding-bottom: 64px;

        @media screen and (max-width: 480px){
            max-width: 320px;
            flex-grow: 1;            
        }

        @media screen and (min-width: 640px) {
            max-width: 400px;
        }

        div.account-type-tab {
            display: flex;
            width: 100%;

            div.tab-resi, div.tab-corp {
                width: 100%;
                padding: 14px 0;
                border-top: 4px solid transparent;
                background-color: #ddd;
                font-size: 16px;
                font-weight: 400;
                line-height: 1;
                color:#000;
                text-align: center;
                transition: 0.3s;
                
                cursor: pointer;

                @media screen and (min-width: 640px) {
                    padding: 16px 0;
                    font-size: 20px;
                }

                &.selected {
                    border-top: 4px solid #e43b44;
                    background-color: #fff;
                }
            }

            div.tab-resi {
                border-radius: 30px 0  0 0;
            }

            div.tab-corp {
                border-radius: 0 30px 0 0;
            }
        }

        div.card {
            box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
            div.card-body {
                padding-left: 20px;
                padding-right: 20px;
                padding-bottom: 40px;

                @media screen and (min-width: 640px) {
                    padding-left: 30px;
                    padding-right: 30px;
                    padding-bottom: 24px;
                }

                div#ndi-qr {
                    div.ndi-container {
                        height: auto;
                    }
                }

                .singpass-btn {
                    padding: 0;
                    img {
                        width: 100%;
                        max-width: 210px;
                        image-rendering: pixelated;
                        @media screen and (min-width: 481px) {
                            max-width: 356px;
                        }
                    }
                    
                }
            }
        }

        .magiclink-form {
            padding: 32px 0;
            .form-group {
                display: flex;
                align-items: center;
                input {
                    width: 100%;
                    padding: 6px 14px;
                    border: none;
                    border-radius: 10px;
                    font-size: 16px;
                    background-color: #ddd;

                    @media screen and (min-width: 640px) {
                        padding: 12px 22px;
                        font-size: 18px;
                    }
                    &#Username {
                        text-transform: uppercase;
                        &::placeholder {
                            text-transform: none;
                        }
                    }
                }
                img.icon {
                    position: absolute;
                    right: 1.1rem;
                    font-size: 22px;

                    @media screen and (max-width: 480px) {
                        right: 0.6rem;
                    }
                }
            }
            button[type=submit] {
                padding: 6px;
                border-radius: 10px;
                margin: 0;
                font-size: 16px;
                @media screen and (min-width: 640px) {
                    padding: 12px;
                    font-size: 18px;
                }
            }
        }

        .divider {
            display: flex;
            position: relative;
            justify-content: center;
            margin: 0;
            div {
                width: 100%;
                padding-top: 16px;
                padding-bottom: 30px;
                border-top: 2px solid #e43b44;

                &.toggle-singpass {
                    padding-bottom: 6px;
                }
            }
            span {
                padding: 0 12px;
                position: absolute;
                top: -8px;
                background-color: #fff;
                font-size: 16px;
                line-height: 1;

                @media screen and (min-width: 640px) {
                    padding: 0 16px;
                    top: -10px;
                    font-size: 18px;
                }
            }
        }

        .loginWithEmail-btn {
            width: 100%;
            // max-width: 210px;
            @media screen and (min-width: 481px) {

                &.show-singpass-btn{
                    img {
                        max-width: 228px;
                    }
                }

                margin-bottom: 14px !important;
            }
        }

        &:not(.container) {
            // width: 360px;
            .card {
                border-radius: 0 0 24px 24px;
                border-top: #D9D9D9;
            }
            .card-body {
                // padding: 40px 35px;
                padding: 0 40px 46px 40px;
                .btn.btn-secondary.btn-lightgray {
                    border-color: #ddd;
                    background-color: #ddd;
                    color:#000;
                    &:hover { background-color: #ccc; }
                }
            }
        }
        @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
            margin: 0 auto;
        }
    }

    @media (min-width: 1200px) {
        .container {
            max-width: 1140px;
        }
    }
    @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
        margin: 0 auto;

        > .row {
            min-height: 100vh;

            > .aut-bg-img {
                min-height: 100vh;
            }
        }
    }

    .active-tab {
        background: #fff !important;
        color: #000 !important;
        border-top: 6px #e43b44 solid !important;
    }

    .saprator {
        position: relative;
        margin: 8px 0;

        span {
            background: #fff;
            position: relative;
            padding: 0 10px;
            z-index: 5;
            font-size: 20px;
        }

        &:after {
            content: "";
            position: absolute;
            top: 15px;
            left: 0;
            width: 100%;
            height: 1px;
            background: $theme-border;
            z-index: 1;
        }
    }

    a,
    p > a {
        color: $theme-heading-color;
        font-weight: 600;
    }

    .input-group {
        background: transparent;
    }

    .card {
        margin-bottom: 0;
        padding: 0 0;

        .card-body {
            padding: 20px 25px 20px 40px;
            // padding: 0 42px;
            
        }
        @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: block;
        }
    }

    > div {
        z-index: 5;
    }

    

    .auth-side-img {
        padding-right: 360px;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        justify-content: center;
        @media only screen and (max-width: 640px) {
            display: none;
        }
    }

    .auth-side-form {
        position: relative;
        min-height: 100%;
        background: #fff;
        display: inline-flex;
        align-items: center;
        left: auto;
        float: right;

        > * {
            position: relative;
            z-index: 5;
        }
        @media only screen and (max-width: 640px) {
            width: 100%;
            justify-content: center;

            .auth-content:not(.container) {
                max-width: 350px;
            }
        }
    }

    &.aut-bg-img {
        .auth-side-form {
            @media (-ms-high-contrast: none), (-ms-high-contrast: active) {
                height: 100vh;
            }
        }
    }
    @media only screen and (max-width: 767px) {
        .card {
            .card-body {
                padding: 30px 15px;
            }
        }
    }

    &.offline {
        background-image: none;

        h1 {
            font-size: 87px;
            font-weight: 700;
        }

        &:before {
            display: none;
        }
    }

    .card-body .carousel-indicators {
        margin: 15px 0 10px;
        bottom: 0;

        li {
            width: 50px;
            background-color: transparentize($primary-color, 0.6);
            border-radius: 5px;
            height: 4px;

            &.active {
                background-color: $primary-color;
            }
        }
    }

    .img-logo-overlay {
        position: absolute;
        top: 40px;
        left: 50px;
    }
}
/* image varient start */
.aut-bg-img {
    background: linear-gradient(rgba(35, 31, 32, 0.24), #231F20), url("./../../../images/auth/img-auth-big.jpg");
    // background-image: url("./../../../images/auth/img-auth-big.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-position: center;

    .auth-content {
        padding: 70px 40px;
    }
}
/* image varient End */
/**====== Authentication css end ======**/


.btn-lg, .btn-group-lg > .btn {
    padding: 0.7rem 3.17rem;
}
$buttonXPadding: 32px;
$buttonYPadding: 8px;

.swal2-popup.swal-login-prompt {
    width: 100%;
    max-width: 487px !important;
    padding-bottom: 32px;
    border-radius: 30px;

    .swal2-html-container {
        padding-bottom: 12px;
    }
    
    .swal2-close {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 35px;
        height: 35px;
        padding: 0;
        border-radius: 6px;
        margin-top: 16px;
        margin-right: 10px;
        background-color: #D4574D;
        font-size: 40px;
        font-weight: 100;
        color: #fff;
    }
    .swal2-icon {
        border: none;
    }
    .swal2-title {
        font-size: 20px;
        font-weight: 700;
        line-height: 1;
        color: #D4574D;
    }
    .swal2-html-container {
        font-size: 12px !important;
        color: #000;
        @media screen and (min-width: 481px) {
            font-size: 24px;
        }
        #unknownAccount-form {
            padding-top: 48px;
            text-align: left;
            .form-group {
                margin-bottom: 10px;
                font-size: 10px !important;
                font-weight: 400;
                line-height: 1;
                .form-label {
                    margin-bottom: 4px;
                }
                .form-control {
                    width: 100%;
                    height: auto;
                    padding: 4px 8px;
                    border: 1px solid #aaa;
                    border-radius: 7px;
                }
            }
        }
    }
    .swal2-actions {
        width: 100%;
        padding: 0 10vw;
        margin-top: 0;
        @media screen and (min-width: 481px) {
            padding: 0 90px;
        }

        button {
            margin: 0 auto;
            border-radius: 100rem;
            font-size: 16px;
            font-weight: 700;
            padding-top: $buttonYPadding;
            padding-bottom: $buttonYPadding;
            @media screen and (min-width: 481px) {
                font-size: 20px;
            }
            &.swal2-confirm {
                padding-left: $buttonXPadding;
                padding-right: $buttonXPadding;
            }

            &:hover, &:active, &:focus {
                box-shadow: none;
            }
        }
    }

    &.thank-you {
        .swal2-title {
            font-size: 16px;
            font-weight: 700;
            line-height: 1;
            color: #000;
        }
        .swal2-actions {
            margin-top: 16px;
        }
    }
}
