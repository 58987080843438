.vqcp-overlay {
  display: flex;
  align-items: center;
  &.full {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    background-color: #fff;
    z-index: 9999;
  }
}

.login-loader-container, .loader-container {
    width: fit-content;
    padding: 64px 0;
    margin: 0 auto;
    .loader {
      border: 16px solid #f3f3f3;
      border-top: 16px solid #cf0b15;
      border-radius: 50%;
      width: 120px;
      height: 120px;
      
      animation: spin 1s linear infinite;
    }
  }
  
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }