div.dashboard-page {
    div.profile-detail-card {
        * {
            word-break: break-all;
        }
        div.detail-group {
            display: flex;
            align-items: start;
            width: 100%;
            padding: 4px 0;

            @media screen and (min-width: 576px) {
                &__name {
                    padding-right: 15px;
                    margin-bottom: 16px;
                }

                &__phone {
                    padding-left: 15px;
                    margin-bottom: 16px;
                }
    
                &__account {
                    padding-right: 15px;
                }

                &__email {
                    padding-left: 15px;
                }
            }
            

            div.icon-container {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: 33px;
                // width: 100%;
                // maxWidth: 33px;
                height: 33px;
                border-radius: 6px;
                margin-right: 12px;
                background-color: #d4574d;

                svg {
                    width: 20px;
                    height: 20px;
                    path { fill: #ffd9d6; }
                }
            }
        }
    }

    div.support-enquiry-card, div.account-balance-card {
        div.card-body {
            display: flex;
            flex-direction: column;
            div.row {
                &.mt-auto {
                    margin-bottom: 45px;
                }
                table {
                    border-spacing: 0;
                    margin: 0;
                    width: 100%;
                    tr {
                        td {
                            padding: 0;
                            border: none;
        
                            font-size: 25px;
                            font-weight: 400;
                            line-height: 1;
                            &.data-value {
                                display: inline-block;
                                width: 100%;
                                text-align: left;
                                text-align: -webkit-left;
                                color: #000;
                                margin-left: -2px;
                            }
                            &.data-label {
                                padding-left: 10px;
                                color: #666;
                            }
                            
                        }
                    }
                }
            }
        }
    }

    .account-balance-card {

        .card-title-wrapper {
            padding-left: 30px;
            padding-right: 30px;
        }

        .current-balance {
            display: flex;
            font-size: 25px;
            font-weight: 400;
            line-height: 1;
            padding-left: 30px;
            padding-right: 30px;
            justify-content: flex-start;
            flex-wrap: wrap;
            margin-left: -2px;

            .data-value {
                overflow: hidden;
                padding-right: 10px;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    div.credit-card-card {
        font-size: 20px;

        div.card-body {
            display: flex;
            flex-direction: column;
        }

        .card_number {
            vertical-align: top;
        } 
    }
}