table.subscription-table {
    border-spacing: 0;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;

    tr {
    padding: .35em;
    }

    th, td {
    padding: .625em;
    text-align: center;
    white-space: pre-wrap;
    border: none;
    }
    
    th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: capitalize;
    
    }

    @media screen and (max-width: 600px) {
        border: 0;

        thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }
        
        tr {
            display: block;
            margin-bottom: .625em;

            &:not(.sub-0){
                display: none;
            }
        }
        
        td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: .8em;
            padding: 10px 4px !important;
            text-align: right !important;
            white-space: pre-wrap;

            &::before {
                /*
                * aria-label has no advantage, it won't be read inside a table
                content: attr(aria-label);
                */
                content: attr(data-label);
                float: left;
                font-weight: bold;
                text-transform: capitalize;
                color: #D4574D;
                width: 50%;
                text-align: left;

                @media screen and (max-width: 336px) {
                    width: 100%;
                    margin-bottom: 5px;
                }
            }

            &:last-child {
                border-bottom: 0;
            }

            @media screen and (max-width: 336px) {
                text-align: left !important;
            }
        }
    }

    @media screen and (min-width: 601px) and (max-width:990px) {
        th, td {
            vertical-align: middle;
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}


.ads-slider {
    position: relative;

    &:hover {
        cursor: pointer;
    }

    button {
        position: absolute;
        right: 20px;
        bottom: 15px;
        border-radius: 20px;
        padding: 9px 20px;
        font-size: 1rem;
        line-height: 1rem;
        background-color: #010101 !important;
        border-color: #010101 !important;
        box-shadow: 0 0 9px #1b1919;

        @media screen and (max-width: 400px) {
            padding: 8px 12px;
            font-size: 0.875rem;
            line-height: 0.75rem;
        }
    }
}

.slick-slider .slick-dots li {
    width: 7px !important;
    padding: 0 8px; 
    button {
        width: 7px !important;
    
        &:before {
            font-size: 7px;
            width: 7px !important;
        }
    }
}

.profile-detail-card {

    @media screen and (max-width: 600px) {
        margin-bottom: 12px !important;

        * {
            font-size: 14px !important;
        }
    }
}

.card-title-wrapper {
    @media screen and (max-width: 600px) {
        flex-wrap: nowrap !important;
    }
}

.ticket-count-wrap {
    display: flex;

    .ticket-count {
        min-width: 16px;
        max-width: 41px;
        text-align: center;
    }

    .ticket-label {
        padding-left: 4px;
    }
}
  
